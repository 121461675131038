import { useEffect, useState } from "react";
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker";
import L from "leaflet";

import ambulanceIcon from "./icons/ambulance.png";
import { Popup } from "react-leaflet";

const icon = L.icon({
  iconSize: [60, 45],
  popupAnchor: [2, -20],
  iconUrl: ambulanceIcon
});

export default function AmbulanceMarker({ data, ref }) {
  const { lat, lng } = data;
  const [prevPos, setPrevPos] = useState([lat, lng]);

  useEffect(() => {
    if (prevPos[1] !== lng && prevPos[0] !== lat) setPrevPos([lat, lng]);
  }, [lat, lng, prevPos]);

  return (
    <LeafletTrackingMarker
      icon={icon}
      position={[lat, lng]}
      previousPosition={prevPos}
      duration={1000}
    >
      <Popup>{"Hello, there! 🐱‍🏍 "}</Popup>
    </LeafletTrackingMarker>
  );
}
