import { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "./styles.css";
import HopitalMarker from "./HopitalMarker";
import EmergencyMarker from "./EmergencyMarker";
import HelicoMarker from "./HelicoMarker";
import AmbulanceMarker from "./AmbulanceMarker";
import Client from "fhir-kit-client";

const fhirClient = new Client({
  baseUrl: 'https://carto.fyrstain.com/fhir'
});

async function getCoordinates(setDatas) {
  const response = await fhirClient.operation({name: '$coordinates', method: 'GET'});
  const datas = [];
  response.parameter.forEach(parameter => {
    var type = parameter.part[0].valueString;
    var longitude = parameter.part[1].valueDecimal;
    var latitude = parameter.part[2].valueDecimal;
    var altitude = parameter.part[3].valueDecimal;
    var reference = parameter.part[4].valueString;
    datas.push({'type': type, 'longitude': longitude, 'latitude': latitude, 'altitude': altitude, 'reference': reference})
  });;

  setDatas(datas)
}

function getElementForCoordinates(data) {
  if (data.type === 'AMBGRND') {
    return <AmbulanceMarker data={{lat: data.latitude, lng: data.longitude} } ref={{ref: data.reference}} />;
  } else if (data.type === 'AMBHELO') {
    return <HelicoMarker data={{lat: data.latitude, lng: data.longitude}} ref={{ref: data.reference}} />
  } else if (data.type === 'ACHC') {
    return <HopitalMarker data={{lat: data.latitude, lng: data.longitude}} ref={{ref: data.reference}} />;
  } else if (data.type === 'ServiceRequest') {
    return <EmergencyMarker data={{lat: data.latitude, lng: data.longitude}} ref={{ref: data.reference}} />;
  }
}


const dataStory = [
  {
    lat: 43.620685670444686,
    lng: 1.3968144541586383
  },
  {
    lat: 43.62083,
    lng: 1.3968144541586383
  },
  {
    lat: 43.62096,
    lng: 1.3968144541586383
  },
  {
    lat: 43.62109,
    lng: 1.3968144541586383
  },
  {
    lat: 43.62112,
    lng: 1.3968144541586383
  }
];

const dataStoryHelico = [
  {
    lat: 43.610685670444686,
    lng: 1.3957144541586383
  },
  {
    lat: 43.61070,
    lng: 1.3957144541586383
  },
  {
    lat: 43.61072,
    lng: 1.3957144541586383
  },
  {
    lat: 43.61074,
    lng: 1.3957144541586383
  },
  {
    lat: 43.61076,
    lng: 1.3957144541586383
  }
];

let cursor = 0;
export default function App() {

  const [datas, setDatas] = useState([])

  const [currentTrack, setCurrentTrack] = useState({});
  const [currentTrackHelico, setCurrentTrackHelico] = useState({});

  useEffect(() => {
    setCurrentTrack(dataStory[cursor]);
    setCurrentTrackHelico(dataStoryHelico[cursor]);

    const interval = setInterval(() => {

      getCoordinates(setDatas);

      if (cursor === dataStory.length - 1) {
        cursor = 0;
        setCurrentTrack(dataStory[cursor]);
        setCurrentTrackHelico(dataStoryHelico[cursor]);
        return;
      }

      cursor += 1;
      setCurrentTrack(dataStory[cursor]);
      setCurrentTrackHelico(dataStoryHelico[cursor]);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <MapContainer
        style={{ height: "calc(100vh - 52px)" }}
        center={[43.61322539080556, 1.399883406747379]}
        zoom={15}
        minZoom={5}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        { datas.map(data => getElementForCoordinates(data)) }
      </MapContainer>
    </div>
  );
}
